<template>
  <v-app class="main-section">
    <div class="logo-container">
      <img
        src="../../../assets/logo.png"
        width="200px"
        alt=""
      >
    </div>
    <v-row>
      <v-col md="3" />
      <v-col md="6">
        <div class="content-section">
          <v-card
            class="my-vcard-styl"
          >
            <br>

            <br>
            <h2 class="title-style">
              User Registration
            </h2>
            <br>
            <v-container class="py-0">
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                >
                  <br>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="firstName"
                        label="First Name*"
                        :rules="[(v) => !!v || 'This field is required']"
                        prepend-inner-icon="mdi-account-outline"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="lastName"
                        label="Last Name*"
                        :rules="[(v) => !!v || 'This field is required']"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <address-form-for-registration
                        ref="addressForm"
                        @addressEntered="assiginAddress($event)"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="createPassword"
                        label="Create Password*"
                        prepend-inner-icon="mdi-lock-outline"
                        :append-icon="showCreatePassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showCreatePassword ? 'text' : 'password'"
                        :rules="createPasswordRules"
                        counter
                        required
                        @click:append="showCreatePassword = !showCreatePassword"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="confirmPassword"
                        label="Confirm Password*"
                        prepend-inner-icon="mdi-lock-outline"
                        :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                        counter
                        required
                        @click:append="showConfirmPassword = !showConfirmPassword"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="phoneNumber"
                        label="Phone Number"
                        type="number"
                        :rules="phoneNumberRules"
                        prepend-inner-icon="mdi-phone-outline"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="organizationName"
                        label="Organization Name"
                        :rules="[(v) => !!v || 'This field is required']"
                        prepend-inner-icon="mdi-office-building"
                        :disabled="InvitationId.invited_to !== null"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="InvitationId.member_role_id.member_role"
                        label="Role"
                        :rules="[(v) => !!v || 'This field is required']"
                        prepend-inner-icon="mdi-key-variant"
                        disabled
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="InvitationId.access_level"
                        label="Access Level"
                        :rules="[(v) => !!v || 'This field is required']"
                        prepend-inner-icon="mdi-badge-account-outline"
                        disabled
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="email"
                        label="Email"
                        disabled
                        :rules="[(v) => !!v || 'This field is required']"
                        prepend-inner-icon="mdi-email-outline"
                      />
                    </v-col>
                  </v-row>
                </v-form>
                <br>
                <p class="text-sm-left">
                  *Indicates required field
                </p>
              </v-card-text>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <v-btn
                class="mt-2"
                color="#FF3700"
                @click="register()"
              >
                Register
              </v-btn>
              <v-btn
                class="mt-2"
                color="red darken-3"
                @click="$router.push({ name: 'Login' })"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
          <br>
          <br>
        </div>
      </v-col>
      <v-col md="3" />
    </v-row>
    <centre-spinner
      :loading="loading"
    />
  </v-app>
</template>
  <script>
    import spinner from 'src/views/dashboard/component/SpinnerCentre';
    import Constants from 'src/constants';
    import InvitedUserAddressForm from './InvitedUserAddressForm';

    export default {
      name: 'InvitedUserSignupForm',
      components: {
        'centre-spinner': spinner,
        'address-form-for-registration': InvitedUserAddressForm,
      },
      data () {
        return {
          firstName: '',
          lastName: '',
          address: {},
          phoneNumber: null,
          email: '',
          createPassword: '',
          confirmPassword: '',
          valid: false,
          organizationId: null,
          organizationName: null,
          showCreatePassword: false,
          showConfirmPassword: false,
          organizationHint: null,
          loading: false,
          createPasswordRules: [v => (v || '').length >= 8 || 'Not strong.Requires at least 8 characters.'],
          confirmPasswordRules: [v => !!v || 'This field is required'],
        };
      },
      computed: {
        InvitationId () {
          return this.$store.getters['registration/getInvitationId'];
        },
        organizationAvailable () {
        if (this.InvitationId.organization !== null) {
         return true;
        }
         return false;
        },
        passwordConfirmationRule () {
          return this.createPassword === this.confirmPassword || 'Password must match';
        },
        phoneNumberRules () {
      return [
        (v) => !!v || 'This field is required',
        (v) => (v && v.length <= 15) || 'Phone number exceeded the limit.',
      ];
    },
      },
      mounted () {
        this.checkIfOrganization();
      },
      methods: {
        register () {
          this.$refs.addressForm.getEnteredAdress();
          if (this.$refs.form.validate() === false || Object.keys(this.address).length === 0) {
            const phoneValid = this.phoneNumber.length <= 15;
            const messages = [];
            if (!phoneValid) {
             messages.push('Phone number must be less than 15 digits.');
            }

            if (messages.length > 0) {
              this.$store.dispatch(
            'alert/onAlert',
            {
              message: messages.join(' '),
              type: Constants.ALERT_TYPE_ERROR,
            },
            { root: true },
          );
          return;
            } else {
              this.$store.dispatch('alert/onAlert', {
              message: 'Please fill the required fields before proceeding',
              type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
              return;
            }
          }
          this.loading = true;
          const id = this.$route.params.key;
          this.$store.dispatch('registration/registerInvitedUser', {
            invitation_key: id,
            email_id: this.email,
            first_name: this.firstName,
            last_name: this.lastName,
            address: this.address,
            password: this.createPassword,
            phone_number: this.phoneNumber,
            organization_name: this.InvitationId.invited_to !== null ? null : this.organizationName,
            invited_to: this.organizationId,
          }).then(response => {
            this.$store.dispatch('alert/onAlert', {
              message: 'User registered successfully.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.address = {};
            this.$router.push({ name: 'Login' });
          }).finally(
            this.loading = false,
            this.$store.dispatch('registration/clearInvitationId'),
          );
        },
        assiginAddress (address) {
          this.address = address;
        },
        checkIfOrganization () {
          if (this.InvitationId.invited_to !== null) {
          this.organizationId = this.InvitationId.invited_to.external_id;
          this.organizationName = this.InvitationId.invited_to.name;
          this.firstName = this.InvitationId.first_name;
          this.email = this.InvitationId.email_id;
          this.lastName = this.InvitationId.last_name;
           } else {
          this.organizationName = this.InvitationId.organization_name;
          this.email = this.InvitationId.email_id;
          this.firstName = this.InvitationId.first_name;
          this.lastName = this.InvitationId.last_name;
  }
      },
      },
    };
  </script>
  <style scoped>
  .my-vcard-style {
    margin: 20px;
    background-color: #ffffff;
    border-radius: 15px;
  }
  .my-base-vcard-style {
    background-color: transparent !important;
    margin-left: 20px;
    margin-right: 20px;
  }
  .title-style {
    color: #37474F;
    text-align: center;
    font-size: 26px;
  }
  .icon-style {
    color: #37474F;
    font-size: 45px;
  }
  .user-id-style {
    margin-top:0px;
    padding-top:0px;
  }
  .background-section{
  background-position: center;
  }

  .form-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-section{
  position: relative;
  background-color: #37474F;
  }
  .logo-container{
    display:flex;
    justify-content: center;
    padding-top:50px;
  }
  .v-row{
    margin-bottom:0px!important;
    padding-bottom: 0px!important;

  }
  </style>
