<template>
  <v-container>
    <v-card
      elevation="0"
      class="my-card-style"
    >
      <v-form ref="form">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="address.line1"
              label="Address line 1/Street"
              :rules="[(v) => !!v || 'This field is required']"
              prepend-inner-icon="mdi-home-outline"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="address.line2"
              label="Address line 2/Street"
              prepend-inner-icon="mdi-home-outline"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="address.city"
              label="City/Suburb"
              :rules="[(v) => !!v || 'This field is required']"
              prepend-inner-icon="mdi-home-city-outline"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="address.state"
              label="State"
              :rules="[(v) => !!v || 'This field is required']"
              prepend-inner-icon="mdi-town-hall"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="address.country"
              :items="countryList"
              label="Country"
              :rules="[(v) => !!v || 'This field is required']"
              prepend-inner-icon="mdi-flag-outline"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="address.postal_code"
              label="Postcode"
              :rules="[(v) => !!v || 'This field is required']"
              prepend-inner-icon="mdi-mailbox-open-outline"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
  <script>
    export default {
      name: 'InvitedUserAddressForm',
      data () {
        return {
          address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            country: '',
            postal_code: '',
          },
        };
      },
      computed: {
        CountryZipCodeList () {
          return this.$store.getters['registration/getCountryCodes'];
        },
        countryList () {
         return Object.keys(this.CountryZipCodeList).map((key) => ({ text: this.CountryZipCodeList[key], value: this.CountryZipCodeList[key] }));
        },
      },
      async mounted () {
        await this.getCountryCodes();
      },
      methods: {
        async getCountryCodes () {
          await this.$store.dispatch('registration/fetchCountryCodes');
        },
        async fillData (fillAddress) {
            this.address.line1 = fillAddress.line1;
            this.address.line2 = fillAddress.line2;
            this.address.city = fillAddress.city;
            this.address.postal_code = fillAddress.postal_code;
            this.address.state = fillAddress.state;
            this.address.country = fillAddress.country;
        },
        getEnteredAdress () {
          if (this.$refs.form.validate() === true) {
            this.$emit('addressEntered', this.address);
          }
        },
      },
    };
  </script>
  <style scoped>
  .my-heading {
    font-size: 18px;
    font-weight: bold;
    color: #263238;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }
  .v-card{
    background-color: #ffffff;
  }
  .col-12{
    padding-left: 0px;
  }
  </style>
